<template>
  <div>
    <div class="login-container">
      <div class="login-header">
        <div class="logo-wrapper">
          <!--<p><img src="../../../static/img/log.jpg" style="width: 50px;height: 45px"/></p>-->
          <p>小管加后台管理</p>
        </div>
      </div>
      <div class="login-wrapper">
        <h2>密码登录</h2>
        <el-form :model="ruleForm" :rules="ruleForm" ref="loginForm" label-width="0">
          <el-form-item label prop="username">
            <el-input v-model="ruleForm.username" placeholder="账号" autofocus="true"
                      @keyup.enter.native="submitForm"></el-input>
          </el-form-item>
          <el-form-item label prop="password">
            <el-input v-model="ruleForm.password" type="password" placeholder="密码"
                      @keyup.enter.native="submitForm"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:10px">
            <el-checkbox v-model="rememberName">记住账号</el-checkbox>
            <el-checkbox v-model="rememberPd" v-if="rememberPd" style="color:rgb(0,82,204)">记住密码</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width:100%;background:rgb(0,82,204)" @click="submitForm('ruleForm')">登入
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      Copyright © 2020 四川志梵文化传播有限公司 <a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2020029084号-1</a>
    </div>
  </div>
</template>

<script>
  import {mapMutations} from 'vuex'
  import {setToken, setMerchant} from "../../utils/auth";

  export default {
    data: function () {
      return {
        ruleForm: {
          username: '',
          password: '',
        },
        rememberName: true,
        rememberPd: false
      }

    },
    // 页面加载调用获取Cookie值
    mounted() {
      this.getCookie();
    },
    methods: {
      async submitForm(ruleForm) {
        // 判断复选框是否被勾选; 勾选则调用配置Cookie方法
        if (this.rememberName && this.rememberPd) {// 记住密码
          this.setCookie(this.ruleForm.username, this.ruleForm.password, 30); // 保存期限为30天
        }
        else if (this.rememberName) { // 记住用户名
          this.setCookie(this.ruleForm.username, "", 30); // 保存期限为30天
        }
        else if (this.rememberPd) { // 记住密码
          this.setCookie(this.ruleForm.username, this.ruleForm.password, 30); // 保存期限为30天
        }
        else {
          this.clearCookie(); // 清空 Cookie
        }


        if (this.ruleForm.username == '' || this.ruleForm.username == null) {
          this.$message({
            showClose: true,
            message: "请输入账号",
            type: 'error'
          });
          return;
        }
        if (this.ruleForm.password == '' || this.ruleForm.password == null) {
          this.$message({
            showClose: true,
            message: "请输入密码",
            type: 'error'
          });
          return;
        }
        let res = await this.$post("/admin/login", {
          loginName: this.ruleForm.username,
          password: this.ruleForm.password
        })
        if (res.code == 200) {
          setToken(res['zb-backend']);
          localStorage.ms_username = res.userName
          localStorage.ms_logourl = res.logoUrl
          this.$router.push("/dashboard");
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },
      // 设置Cookie

      setCookie(username, password, exdays) { // 用户名, 密码, 保存天数
        let exdate = new Date(); // 获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);
        // 字符串拼接cookie
        window.document.cookie = 'userName=' + username + ';path=/;expires=' + exdate.toGMTString();
        window.document.cookie = 'userPwd=' + password + ';path=/;expires=' + exdate.toGMTString();
      },

      // 读取Cookie
      getCookie() {
        if (document.cookie.length > 0) {
          let arr = document.cookie.split('; '); // 这里显示的格式需要切割一下自己可输出看下
          for (let i = 0; i < arr.length; i++) {
            let arr2 = arr[i].split('='); // 再次切割
            // 判断查找相对应的值
            if (arr2[0] == 'userName') {
              this.ruleForm.username = arr2[1]; // 保存到保存数据的地方
            } else if (arr2[0] == 'userPwd') {
              this.ruleForm.password = arr2[1];
            }
          }
        }
      },

      // 清除Cookie
      clearCookie() {
        this.setCookie('', '', -1); // 修改2值都为空，天数为负1天就好了
      }
    }


  }
</script>

<style lang="scss">
  .login-header {
    width: 100%;
    height: 68px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    border-radius: 0px;
    box-shadow: 0px 3px 3px rgba(0, 82, 204, 0.0980392156862745);
    .logo-wrapper {
      display: flex;
      align-items: center;
      margin: 0 auto;
      max-width: 1000px;
      height: 100%;
      p {
        padding-left: 10px;
        font-size: 20px;
        font-weight: 700;
        color: #2878ff;
      }
    }
  }

  .login-wrapper {
    margin: 80px auto 0;
    padding: 40px 30px 0;
    width: 400px;
    height: 430px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    border-radius: 0px;
    box-shadow: 0px 0px 20px rgba(0, 82, 204, 0.3);
    h2 {
      font-size: 16px;
      padding-bottom: 30px;
    }
  }

  .footer {
    width: 100%;
    height: 30px;
    margin-top: 16%;
    text-align: center;
    color: #999999;
  }
</style>
